html, body
  margin: 0px
  // padding: 0px
  background: #272c32
  // height: 100vh
  // width: 100vw
  overflow-x: hidden // NON ATTIVARE ASSOLUTAMENTE, blocca gsap. a quanto pare invece non era questo il responsabile, ma l'aver messo le animazioni gsap scrolltrigger in ordine diverso da come appaiono sul dom.
  // position: relative
  ::-webkit-scrollbar
    display: none
  h1,
  h2,
  h3,
  p 
    margin: 0

// $placeHolder_FB: 75.5%
// $spacer_FB: 1%
// $px2vw_vert: 1/18*9/16*1vw
// $px2vw-hor: 1/32*1vw
// $FBLogo_size: 30vh
// $offsetParametricLandscape: 35vh


/////////////////////////////////////////////// Mobile
section
  h1
    font-family: 'Market Fresh Bold All Caps', avenir next, avenir, helvetica neue
    font-size: 10vw
    // box-sizing: border-box
    font-style: normal
    text-align: center
    color: #eeeeeeee
    text-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.2)
    user-select: none  
    margin: 6vh auto 2vh
  h2 
    font-family:  'Market Fresh', avenir next, avenir, helvetica neue
    // font-weight: 20
    font-size: 8vw
    text-align: center
    // padding-top: 2vh
    color: #f7df0aee
    text-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.2)
    user-select: none  
  h3
    font-family: 'Market Fresh', avenir next, avenir, helvetica neue
    font-weight: 200
    font-size: 5.5vw
    // box-sizing: border-box
    font-style: normal
    // color: #4e5b6f
    text-align: center
    color: #f7df0a4d
    text-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.2)  
    user-select: none 
  p
    // margin: 10vh
    // margin-top: 2vh
    // margin-bottom: 2vh
    // display: block
    margin: 2vh auto 2vh // top left-right bottom
    font-family: avenir next, avenir, 'Market Fresh'
    font-weight: 600
    font-size: 6.2vw
    box-sizing: border-box
    // font-style: Semi-bold
    color: #eeeeeeee
    user-select: none  
    text-align: center
    letter-spacing: -0.2px
    line-height: 120%
    // border: solid 1px red
    // width: 85vw
    // border: solid 1px red
    // z-index: 1
    // margin-top: 5vw
    // color: #fadb03
    
.description 
  position: relative
  // display: block
  width: 100%
  // height: auto
  // padding: 0 4vw 0
  margin: 5vh auto // generico, controllare se 5vh funziona bene
  box-sizing: border-box // in generale è meglio, se si dovessero aggiungere padding o border
  p
    width: 88%
  // margin: 2vh auto 2vh // top left-right bottom


.subText
  font-weight: 500
  font-size: 4.5vw
  opacity: 0.6

.forDesktop
  display: none
.forMobile
  display: block  


.viewport
  position: relative
  width: 100vw
  height: 100vh
  overflow: hidden // può combinare casini con gsap
  background-repeat: no-repeat
  background-size: 100% 100%

#loadingScreen
  background: radial-gradient(#0b2f40, #001a25)
  position: fixed
  z-index: 5
  #loader-container
    position: relative
    text-align: center
    top: 35vh
    // border: solid 1px blue
    img
      position: relative
      // display: block
      width: 25vw
      filter: drop-shadow(0 0 5vw rgba(0,0,0,0.3))
      opacity: 0.95
      // border: solid 1px red
#progressBarContour
  position: relative
  display: block
  margin: 2vh auto 
  height: 1vw
  width: 30%
  border-radius: 1vh
  box-sizing: border-box
  background-color: #00000055

#progressBar
  position: absolute
  left: 0
  top: 0
  box-sizing: border-box
  width: 0%
  height: 100%
  border-radius: 1vh
  background-color: #089cc1
  box-shadow: -0.25px -0.25px 1px #000000c7 inset, 0.25px 0.25px 1px #ffffffc7 inset


    
  


.intestazione-container
  display: block
  position: relative
  // left: 5vw
  top: 10vh
  margin: auto
  // left: auto
  // margin-top: 5vh
  // max-width: 80vw
  text-align: center
  // background-color: rgb(109, 254, 104)
  z-index: 3
  opacity: 0 // potrà essere tolta una volta predisposta la schermata di carimento iniziale
  // background-color: olive
  #FBLogo
    width: 35vw
    margin: auto
    // height: auto
    display: block
    // float: none
    // padding-right: 0.8cm
    opacity: 0.95
    filter: drop-shadow( 1px 1px 4px rgba(0, 0, 0, 0.5)) 
    // vertical-align: center
    // border: solid 1px purple
  h1
    // margin-top: 5vh
    font-family: Hyperwave2 ,'Market Fresh Inline Bold All Caps', avenir next, avenir, helvetica neue
    font-size: 26vw
    // font-weight: bold
    box-sizing: border-box
    // font-style: normal
    letter-spacing: 0vw
    // margin-left: 20vh
    // color: #272c32
    color: #ffffff
    line-height: 65%
    display: block // si prende tutta la linea. inline-block invece solo il contenuto
    margin: 1vh auto 0vh
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5)
    // text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2), -1px -1px 1px rgba(255, 255, 255, 1)
    // text-shadow: 0px 0px 50px rgba(25, 196, 226, 0.555)
    // border: solid 1px blue
    // background-color: olive
  h2
    font-family: aberforth ,Market Fresh Bold All Caps, avenir next, avenir, helvetica neue
    // margin: auto
    display: block
    // margin: auto
    text-align: center
    font-weight: 100
    font-size: 9vw
    box-sizing: border-box
    // font-style: normal
    letter-spacing: -1.5px
    color: rgba(255,255,255,0.95)
    // padding-top: 1.6vw
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5)
    // letter-spacing: 0.42vw
    // display: inline-block



#parametricLandscape-container
  // margin-top: 10vh
  // display: block
  position: absolute
  top: -22vw
  width: 100vw
  height: 250vw
  // border: solid 1px red
  z-index: 2
  touch-action: pan-y
  // box-sizing: border-box


#v1
  background-image: url("../static/earlyWorks/images/v1-bgMw.jpg") //bg=background, w=web (export fro web con photoshop. jpg qualità 60%, res. 1920x1080)
  height: 190vh
  // background: radial-gradient(#222222, #107284)

  // background: mix(#3f3251, #002025, 70%)
  // background-image: -moz-linear-gradient(45deg, #3f3251 2%, #002025 100%)
  // background-image: -webkit-linear-gradient(45deg, #3f3251 2%, #002025 100%)
  // background-image: radial-gradient(#3f3251 2%, #002025 100%)

  // border: solid 1px #f80ed9
  // border-radius: 20px



#v-Modellazione3D
  background-image: url("../static/earlyWorks/images/v-Modellazione-bgMw.jpg")
  // border: solid 1px red
  height: 110vh

div.flex-container
  display: flex
  flex-flow: row wrap // gli items vanno a capo quando non hanno spazio sufficiente. Senza questo verrebbero stretchati
  justify-content: center // allineamento orizzontale items
  align-items: center //allineamento verticale items
  div
    position: relative // devono servire come riferimento per i children absolute
    margin: 2.5vw 5vw 2.5vw
    // border: solid 1px red
    span
      position: relative
      top: 100%
      left: 0%
      display: block
      // width: 20vw
      text-align: center
      font-family: avenir next, avenir, helvetica neue, 'Market Fresh'
      // font-weight: 300
      font-size: 4vw
      // box-sizing: border-box
      font-style: normal
      color: #eeeeee81
      user-select: none

      // border: solid 1px blue

      // transform: translateY(4vw)
      // left: 10vh
      // transform-origin: 50% 0%

.logo
  filter: drop-shadow(0.2cm 0.4cm 0.4cm rgba(0, 0, 0, 0.5)) // per le img png
  width: 22vw
  // align-content: center
  // align-items: center
  // border: solid 1px red

.logo-small
  height: 8vw

//   // filter: drop-shadow(0cm 0cm 0.2cm rgba(0, 0, 0, 0.1)) // per le img png


// #logo-Rhino
//   width: 20vw
//   height: auto

#logo-Grasshopper 
  position: absolute
  left: 75%
  top: 40%
  width: 12vw
  height: auto
  // left: 80%
  // top: 75% // spostato e trasformato dall'angolo in basso a destra del logo di Rhino
  // transform: none
  filter: drop-shadow(0.3cm 0.5cm 0.5cm rgba(33, 255, 25, 0.158)) // per le img png

// div#softwares3D // per qualche ragione occorre dichiarare tutto il percorso altrimenti la dichiarazione precedente - quella per tutti i loghi - vince. Sembra avere la precedenza.
//   div#logoRhino-container
//     span
//       bottom: 0vh
//       transform: translateX(-30%)

#v-Grasshopper
  background-image: url("../static/earlyWorks/images/v-Grasshopper-bgMw.jpg")
  overflow: visible
  height: 165vh
  // background-size: 270% 100%
  // background-position: 80%
  // border: solid 1px


#grassScreenshot-img
  width: 250vw
  height: auto
  position: absolute
  left: -20vw
  top: -50vw

// #grassScreenshot
//   background-image: url("../static/earlyWorks/images/grassScreenshot.png")
//   background-size: contain
//   // background-position: top
//   background-repeat: no-repeat
//   // transform: translateY(-5%)
//   // width: 80vw
//   height: 65vh
//   border: 1px solid #ddd


.conch-img
  display: none
//   position: absolute

.conchtop-img
  position: absolute
  display: block
  width: 100vw
  bottom: 70vw
  // margin: 0
  filter: drop-shadow(0cm 0cm 10vw rgba(0, 0, 0, 0.5))


// #conch1
//   top: 323/1080*100vh
//   left: 135/1920*100vw
//   height: 316/1080*100vh
//   filter: drop-shadow(-0.5cm 0.5cm 0.5cm rgba(0, 0, 0, 0.2))
//   opacity: 1

// #conch2
//   top: 289/1080*100vh
//   left: 261/1920*100vw
//   height: 446/1080*100vh
//   filter: drop-shadow(-1cm 0.5cm 1cm rgba(0, 0, 0, 0.5))
//   opacity: 1

// #conch3
//   top: 194/1080*100vh
//   left: 456/1920*100vw
//   height: 789/1080*100vh
//   filter: drop-shadow(-1cm 0.5cm 1.5cm rgba(0, 0, 0, 0.6))
//   opacity: 1

#grasshopper-text
  position: absolute
  display: inline-block
  bottom: 0vh
  left: 5vw
  text-align: left
  max-width: 90vw


#v-Fresata
  background-image: url("../static/earlyWorks/images/v-Fresata-bgMw.jpg")


$fresaSize: 100vh

#fresata-container
  // margin: auto
  height: $fresaSize
  width: 100vw
  display: flex
  align-content: center
  justify-content: center
  // background-color: olive


#fresataBW
  background-image: url("../static/earlyWorks/images/fresataOrganicaBW.png")
  position: absolute
  // left: 0
  // top: 0
  background-position: center
  background-repeat: no-repeat
  background-size: cover
  height: 1284/1284*$fresaSize
  width: 1450/1284*$fresaSize // è un problema per landscape
  z-index: 1
  // box-shadow: 0 0 20px 5px #000000 // implementare ombra animata allo spostamento


#fresataPurple
  background-image: url("../static/earlyWorks/images/fresataOrganicaPurpleColor.png")
  position: absolute
  background-position: center
  background-repeat: no-repeat
  background-size: cover
  height: 1284/1284*$fresaSize
  width: 1450/1284*$fresaSize // è un problema per landscape
  z-index: 2

.overlay-black
  position: absolute
  width: 100%
  height: 100%
  background-color: black
  z-index: 0
  opacity: 0
  left: 0
  top: 0
  // background: radial-gradient(#071f28, #001218)


// // $fresaSpanSize:  - 1450/1284/2 * $fresaSize + (30 + 50) * 16vh/9 //aspect ratio


#fresata-text
  position: absolute
  display: block
  padding: 0vh 5%
  // margin-right: 4vh
  // right: 0
  margin: auto // aggiunto rispetto a Desktop
  top: 50vh
  transform: translateY(-50%)
  // float:right
  // width: 80vw
  // box-sizing: border-box

#fresataLegno
  position: absolute
  margin: 0 5vw 0 // aggiunto rispetto a Desktop
  // padding: 0vh 5vh
  // margin-right: 4vh
  // right: 0
  top: 50vh
  transform: translateY(-50%)
  // box-sizing: border-box
  // float:right
  width: 90vw
  height: 90vh
  background-image: url("../static/earlyWorks/images/fresataLegno.jpg")
  background-size: contain
  background-position: center
  background-repeat: no-repeat

#v-Abbraccio
  height: 100vw /9*16
  background-image: url("../static/earlyWorks/images/v-Abbraccio-bgVw.jpg")
  background-repeat: no-repeat
  background-size: cover
  // background-position: 80%


#abbraccio-text
  position: absolute
  // padding: 0vh 4vh
  // margin-right: 4vh
  // right: 0
  bottom: 0vw
  margin: 0
  // transform: translateY(-50%)
  // float:right
  // max-width: 35vw
  // box-sizing: border-box
  p
    // text-align: left
    // margin-top: 0
    // margin-bottom: 1vw
    margin: 0 auto
    color: #272c32ee
    // font-weight: 700
    img
      // display: inline-block
      // bottom: 0
      opacity: 0.8
      width: 8vw
      height: auto // correzione grandezza loghi perché finiscono sull'abbraccio
      // float: right
      // vertical-align: bottom

#abbraccioOverlay
  position: absolute
  width: 100%
  height: 100%
  opacity: 0
  background: rgb(0,0,0)
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)

// SHOES

#v-Shoes
  background-image: url("../static/earlyWorks/images/v-Shoes-bg.jpg")
  height: 100vh
  background-size: 270% 100%
  background-position: 80%

  // border: solid 1px red

#shoesOverlay
  // background-color: #eeeeee
  // background: radial-gradient( #eeeeee 0%, #c7c7c7 100%)
  position: absolute
  width: 100%
  height: 100%
  background-size: 100% 100%
  // background-color: black
  z-index: 0
  // opacity: 1
  left: 0
  top: 0

.shoes-img
  position: absolute
  width: 100vw
  height: auto
  left: 50vw
  top: 40vh
  // bottom: 10vw
  transform: translate(-50%, 0%)

#shoes-wireframe
  filter: drop-shadow(4vw 3vw 5vw rgba(0, 0, 0, 0.3)) // per le img png

#shoes-text
  // position: absolute
  // bottom: 1vw // aggiunto rispetto a Desktop
  // padding: 0vh 14vh
  // margin-right: 4vh
  // left: 0
  top: 1vh
  // transform: translateY(-50%)
  // float:right
  // width: 90vw
  // box-sizing: border-box
  // border: solid 1px red
  p
    color: #272c32ee
    // &:first-child
    //   font-weight: 700

// SFERA

#v-Sfera
  background-image: url("../static/earlyWorks/images/v-Sfera-bg.jpg")
  height: 120vh
  background-size: 270% 100%
  background-position: 80%


#sfera-img
  position: absolute
  width: 90vw
  height: auto
  transform: translate(-50%, 0%)
  left: 50vw
  bottom: 38vh
  filter: drop-shadow(0vw 1vw 2vw rgba(0, 0, 0, 0.5))


#sfera-text
  position: absolute
  bottom: 0vw
  // padding: 0vh 14vh
  // margin-right: 4vh
  // left: 0
  // top: 50%
  // transform: translateY(-50%)
  // float:right
  p
    color: #f6f4ec

// LESMO

#v-Lesmo
  background-image: url("../static/earlyWorks/images/v-Lesmo-bg.jpg")
  border-bottom: solid 3px rgba(0,0 ,0 ,0.1 )
  box-sizing: border-box
  background-size: 270% 100%
  background-position: 80%


#v-LesmoGallery
  height: 340vh
  height: auto
  background-image: url("../static/earlyWorks/images/v-LesmoGallery-bg.jpg")
  background: linear-gradient(180deg, #272c32 0%, rgba(6,55,72,1) 100%)


#lesmoOverlay
  background-image: url("../static/earlyWorks/images/v-lesmo-bgOverlay.jpg")
  position: absolute
  width: 100%
  height: 100%
  background-size: 270% 100%
  background-position: 80%
  // background-color: black
  z-index: 0
  // opacity: 1
  left: 0
  top: 0

// #lesmo-title 
//   left: 24vw
//   top: 5vh

.title
  // letter-spacing: 0px // è già 0 a quanto pare
  font-size: 20vw
  font-weight: bolder
  font-family: 'Market Fresh Bold All Caps'
  margin-top: 5vw
  // position: absolute
  text-shadow: 0 0 4vw rgba(0, 0, 0, 0.2)


#lesmo-text
  // position: absolute
  // padding: 0vh 0vw
  // text-align: left
  // right: 0
  // top: 35vh
  // width: 90vw
  // box-sizing: border-box
//   all: initial
// * 
//   all: unset
  // p
  //   color: #eeeeee
    // font-weight: 600
  // img
  //   float: right

#lesmo-btm-border
  position: absolute
  bottom: 0
  width: 100%
  height: 1cm
  background-color: #eeeeee71
  z-index: 0



#lesmo-facciata-img
  position: absolute
  bottom: 0
  left: 0
  // left: auto
  // transform: none
  width: 135vw
  height: auto
  z-index: 1



// #lesmo-gallery-container
//   // margin-top: 5vh
//   position: absolute
//   width: 100vw
//   // background-color: gray


.lesmo-gallery-img
  position: relative // così rimangono nel flow del documento e occupano spazio, e con top left ecc. si possono muovere rispetto alla posizione di partenza.
  display: block // così ogni immagine occupa una riga.
  // margin: auto // in più rispetto a Desktop
  width: 100%
  height: auto
  // border-radius: 0.3cm
  filter: drop-shadow(0vh 0vh 1cm rgba(0, 0, 0, 0.2))  // per le img png
  // border: solid 1px red




// PROGRAMMAZIONE

#v-Programmazione
  background-color: rgb(6,55,72)
  height: auto
  h1
    // margin-top: 8vh


// // TOLOMEO
  
#v-Tolomeo
  background-image: url("../static/earlyWorks/images/v-Tolomeo-bgw.jpg")
  height: auto
  background-size: 270% 100%
  background-position: 80%



#Tolomeo-container  
  position: relative
  width: 100vw
  height: 90vh
  // box-sizing: border-box
  // border: solid 1px red

#Tolomeo-title
    background-image: linear-gradient(70deg, #eeeeeedd, #eeeeee00)
    background-size: 100%
    -webkit-background-clip: text
    -webkit-text-fill-color: transparent

//   right: 10.5vw  
//   top: 3.37vw
//   text-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.3)

// #startButton-Tolomeo
//   position: absolute
//   height: 10vh
//   width: 10vh
//   border-radius: 5vh
//   background-color: red
//   left: 50%
//   top: 50%
//   transform: translate(-50% -50%)
//   z-index: 4

// #Tolomeo-span
//   position: absolute
//   padding: 0vh 10vh 0vh 0vh
//   right: 0vh
//   top: 40%
//   transform: translateY(-50%)
//   width: 40vw
//   box-sizing: border-box
//   text-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.3)

  
// .italic
//   font-style: italic  

// .regular
//   font-style: normal 

// PROTOTIPAZIONE E BLINK

#v-Prototipazione
  height: auto
//   h1
//     text-align: center
//     font-size: 10vh
//     text-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.2)
//   h2
//     text-align: center
//     font-size: 8vh
//     color: #f7df0a
//   h3
//     text-align: center
//     color: #f7df0a
//     font-size: 10vh
//     text-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.2)  
//   p 
//     text-align: center
//     font-weight: 600
//     max-width: 50%
//     margin: auto


#v-blink
  height: auto
  background-color: #272c32
  // background: radial-gradient( #30373e 0%, #1d2125 100%)
  background: linear-gradient( #30373e 0%, #1d2125 100%)
  text-align: center


#blinkLogo-img  
  position: relative
  display: block
  // width: 100vw
  margin: auto
  height: 35vw
  top: 20vw
  z-index: 1
  filter: drop-shadow(0vw 0vw 5vw rgba(0, 0, 0, 0.5)) // per le img png
  // margin-top: 10vw
  // background-color: olive

#blinkCentral-img
  position: relative
  display: block
  margin: auto
  width: 100vw
  // top: -0vw

  filter: drop-shadow(0vw 0vw 5vw rgba(0, 0, 0, 0.5)) // per le img png

  // border: solid 1px white


#blinkSite-btn
  position: relative
  display: inline-block
  // width: 
  // right: 10vw
  // margin: 1vw auto 1vw // aggiunto rispetto a Desktop
  // bottom: 10vh
  // background-color: #272c32
  border-radius: 4vh
  // box-sizing: border-box
  padding: 2vw 3vw
  margin: 4vw auto 8vw
  background: linear-gradient(140deg, #00afdbd5 0%, #00ffd5d3 100%)
  border: solid 2px rgba(0,0,0,0.3)
  user-select: auto
  cursor: pointer
  &:hover
    border: solid 2px rgba(255,255,255,0.5)
  a
    font-family: avenir next, avenir, helvetica neue, 'Market Fresh'
    font-weight: 600
    font-size: 6vw
    color: #eeeeee
    text-decoration: none
    cursor: pointer





#moreSymbol
  font-family: consolas // mi sa che va scaricato
  font-size: 6vw
  color: #eeeeee


// .floatingZ-centered // eventualmente eliminarlo. centrare con transform non funziona qualora l'oggetto sia animato con gsap e traslato, perché la traslazione agisce con un transform che sovrascrive quello di partenza. (In realtà non sono sicuro sia questo il motivo, perché con il logo blink dava problemi ma con l'immagine centrale blink non ha mai dato problemi. La centrava eppure viene traslata da gsap)
//   position: absolute
//   // display: block
//   left: 50%
//   transform: translate(-50%, 0%)
//   // margin: auto
//   // border: solid 1px red

// CROGIUOLO

#v-Crogiuolo
  background-image: url("../static/earlyWorks/images/sfondo_crogiuolo.jpg")
  background-repeat: no-repeat
  background: linear-gradient( #1d2125 0%, #101214 100%)
  // height: 100vh
  // background-size: cover
  // background-position: center
  // height: auto

#crg-container
  // background-color: olive
  position: absolute // modificata da absolute per Desktop
  width: 100vw // era 70
  height: 100vh
  left: 0
  top: 0
  touch-action: pan-y
  


#crg-text
  // position: relative
  top: 20vh
  text-shadow: 0 0 5vw rgba(0,0,0,1)
  z-index: 1
  // transform: translate(-50%, 0%)
  // border: solid 1px red
//   // float:right
//   width: 45vw
//   box-sizing: border-box
























// @media screen and (max-width: 767px) and (orientation: landscape) // per mobile in landscape mode
//   // #pleaseTurn
//   //   display: block
//   div.intestazione
//     // display: block
//     // vertical-align: top
//     text-align: left
//     // margin-left: 5vh

//     div#FBlogo-container
//       display: inline-block
//       margin-top: 5vh
//       margin-bottom: 3vh
//       height: $FBLogo_size*1.25
//       width: $FBLogo_size*1.25
//     div.intestazione-text

//     h1
//       font-weight: 700
//       font-size: 10vh
//       box-sizing: border-box
//       font-style: normal
//       color: #fadb03
//     h2
//       font-weight: 700
//       font-size: 8vh
//       box-sizing: border-box
//       font-style: normal
//       color: #4e5b6f

  



// @media screen and (min-width: 768px) and (orientation: landscape) // per tablet in landscape mode e desktop (sempre in landscape mode si suppone)
//   body
//     background: #272c32
//     // background: #eeeeee

  // background-image: url("../static/earlyWorks/media/1_Sfondo.jpg")
// div.intestazione
//   // display: block
//   // vertical-align: top
//   text-align: left
//   // margin-left: 5vh

//   div#FBlogo-container
//     display: inline-block
//     position: static
//     margin-top: 3vh
//     // margin-bottom: 3vh
//     height: $FBLogo_size
//     width: $FBLogo_size
//   div.intestazione-text
//     // display: inline-block
//     position: absolute
//   h1
//     font-family: 'market_fresh', avenir next, avenir, helvetica neue
//     font-weight: 700
//     font-size: 10vh
//     box-sizing: border-box
//     font-style: normal
//     color: #fadb03
//     // margin-left: $FBLogo_size*0.3
//   h2
//     font-family: 'market_fresh', avenir next, avenir, helvetica neue
//     font-weight: 700
//     cursor: pointer
//     font-size: 10vh
//     box-sizing: border-box
//     font-style: normal
//     color: #4e5b6f
//     // margin-left: $FBLogo_size*0.3


  

























































































































/////////////////////////////////////////////// Desktop
@media screen and (orientation: landscape) // and (min-width: 767px)


  body
    // background-color: olive
    // font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif
    // font-weight: 700
    // margin: 0
    // padding: 0

  h1,
  h2,
  h3,
  p 
    margin: 0

  // @font-face 
  //   font-family: "market_fresh_bold"
  //   src: url("../static/fonts/webfontkit-20210327-075150/market_fresh_bold-webfont.woff2") format("woff2")
  //   font-weight: normal
  //   font-style: normal

  // @font-face 
  //   font-family: "market_fresh"
  //   src: url("../static/fonts/webfontkit-20210327-075150/market_fresh-webfont.woff2") format("woff2")
  //   font-weight: normal
  //   font-style: normal  


  section
    h1
      font-family: 'Market Fresh All Caps', avenir next, avenir, helvetica neue
      font-size: 10vh
      // box-sizing: border-box
      font-style: normal
      text-align: center
      color: #eeeeeeee
      text-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.2)
      user-select: none  
      margin: 8vh auto 1vh
    h2 
      font-family: 'Market Fresh', avenir next, avenir, helvetica neue
      font-weight: 20
      font-size: 8vh
      text-align: center
      padding-top: 2vh
      color: #f7df0aee
      text-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.2)
      user-select: none  
    h3
      font-family: 'Market Fresh', avenir next, avenir, helvetica neue
      font-weight: 200
      font-size: 5.5vw
      // box-sizing: border-box
      font-style: normal
      // color: #4e5b6f
      text-align: center
      color: #f7df0a4d
      text-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.2)  
      user-select: none 
    p
      // margin: 2vh 0 2vh // top left-right bottom
      font-family: avenir next, avenir, helvetica neue, 'Market Fresh'
      font-weight: 600
      font-size: 4vh
      box-sizing: border-box
      font-style: normal
      color: #eeeeeedd
      user-select: none  
      text-align: left // diversamente da Mobile, qui il testo è sempre left di base. Tipicamente portato a center nelle singole description prese singolarmente con id
      letter-spacing: 0px
      line-height: normal


  .description
    position: relative
    width: 100%
    margin: 0 // annulla margin Mobile
    // padding: 0 // annulla il Mobile
    // border: solid 1px red
    p
      width: auto // annulla Mobile
      

  .subText
    font-weight: normal
    font-size: 2.6vh
    // line-height: 

  .forDesktop
    display: block

  .forMobile
    display: none  


  .viewport
    position: relative
    // width: 100vw
    // height: 100vh
    width: 100%
    height: 100vh
    overflow: hidden // può combinare casini con gsap
    background-repeat: no-repeat
    background-size: 100% 100%
    background-position: center
    // border: dotted 1px blue

  #loadingScreen
    #loader-container
      top: 40vh
      // border: solid 1px blue
      img
        width: 15vh
        filter: drop-shadow(0 0 5vh rgba(0,0,0,0.2))
        opacity: 0.95
        // border: solid 1px red
  #progressBarContour
    margin: 5vh auto 
    height: 0.5vh
    width: 20%
    border-radius: 1vh
    box-sizing: border-box
    background-color: #00000055

  #progressBar
    width: 0%
    height: 100%
    border-radius: 1vh
    background-color: #089cc1
    box-shadow: -0.25px -0.25px 1px #000000c7 inset, 0.25px 0.25px 1px #ffffffc7 inset

    




  .intestazione-container
    // border: solid 1px #00e1ff
    display: inline-block
    position: relative
    left: 10vw
    top: 15vh
    // margin-top: 5vh
    // max-width: 80vw
    text-align: left
    // background-color: rgb(109, 254, 104)
    z-index: 3
    opacity: 0 // potrà essere tolta una volta predisposta la schermata di carimento iniziale
    #FBLogo
      width: 13vw
      // height: auto
      display: inline-block
      float: left
      padding-right: 0.8cm
      opacity: 0.9
      filter: drop-shadow( 2px 2px 2px rgba(0, 0, 0, 0.25,)) drop-shadow( -1px -1px 2px rgba(255, 255, 255, 0.5))
      // vertical-align: center
      // border: solid 1px purple
    // background-color: olive
    h1
      // margin-top: 5vh
      font-family: 'Market Fresh Inline Bold All Caps', avenir next, avenir, helvetica neue
      font-size: 8.4vw
      font-weight: bold
      box-sizing: border-box
      font-style: normal
      letter-spacing: -0.4vh
      // color: #272c32
      color: #ffffffee
      line-height: 0.9 * 8.4vw
      display: inline
      vertical-align: top
      text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2), -1px -1px 2px rgba(255, 255, 255, 1)
      // text-shadow: 0px 0px 50px rgba(25, 196, 226, 0.555)
      // border: solid 1px blue
      // background-color: olive
    h2
      font-family: 'Market Fresh Bold All Caps', avenir next, avenir, helvetica neue
      text-align: left
      font-weight: 100
      font-size: 4.2vw
      box-sizing: border-box
      font-style: normal
      // color: #272c32
      color: #ffffffe1
      padding-top: 1.6vw
      text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2)
      line-height: 0.9 * 5.6vw
      letter-spacing: 0.1vh
      // display: inline-block



  #parametricLandscape-container
    // margin-top: 10vh
    // display: block
    position: absolute
    top: -35vh
    width: 100vw
    height: 200vh
    // border: solid 1px red
    z-index: 2

  #v1
    background-image: url("../static/earlyWorks/images/v1-bgw.jpg") // M sta per Mobile e w per export for Web di photoshop
    background-repeat: no-repeat
    background-size: 100% 100%
    // background-size: contain
    background-position: top
    background-color: #272c32
    height: 200vh - 35vh
    // border: solid 1px #f80ed9
    // border-radius: 20px

  #v-Modellazione3D
    p 
      text-align: center
      max-width: 50%
      // border: solid 1px red
      margin: auto
      // margin-top: 5vh
      // color: #fadb03


  #v-Modellazione3D
    background-image: url("../static/earlyWorks/images/v-Modellazione-bg.jpg")

  #v-Grasshopper
    background-image: url("../static/earlyWorks/images/v-Grasshopper-bg.jpg")
    overflow: visible
    height: 100vh

    // border: solid 1px

  #v-Fresata
    background-image: url("../static/earlyWorks/images/v-Fresata-bg.jpg")


  #v-Abbraccio
    background-image: url("../static/earlyWorks/images/v-Abbraccio-bg.jpg")
    height: 100vh
    background-repeat: no-repeat
    background-size: cover



  #v-Lesmo
    background-image: url("../static/earlyWorks/images/v-Lesmo-bg.jpg")
    background-size: 100% 100%
    // border-bottom: solid 10px rgba(255,255 ,255 ,0.8 )
    // background-repeat:

  #v-LesmoGallery
    height: 275vh
    // height: auto
    // background-image: url("../static/earlyWorks/images/v-LesmoGallery-bg.jpg")
    // background:       


  #v-Tolomeo
    background-image: url("../static/earlyWorks/images/v-Tolomeo-bg.jpg")
    height: 100vh

  #v-Crogiuolo
    // background-image: url("../static/earlyWorks/images/sfondo_crogiuolo.jpg")
    // background-repeat: no-repeat
    // background-size: cover
    // background-position: center



  div.flex-container
    display: flex
    flex-flow: row wrap // gli items vanno a capo quando non hanno spazio sufficiente. Senza questo verrebbero stretchati
    justify-content: center // allineamento orizzontale items
    align-items: flex-start //allineamento verticale items
    div
      position: relative // devono servire come riferimento per i children absolute
      margin: 4vh 2vh 4vh
      // border: solid 1px red
      span
        position: relative
        // top: 100%
        // left: 0%
        // display: block
        // width: 20vw
        text-align: center
        font-family: avenir next, avenir, helvetica neue, 'Market Fresh'
        // font-weight: 300
        font-size: 3vh
        // box-sizing: border-box
        font-style: normal
        color: #eeeeee81
        user-select: none
        // border: solid 1px blue

        // transform: translateY(4vw)
        // left: 10vh
        // transform-origin: 50% 0%

  .logo
    filter: drop-shadow(0.2cm 0.4cm 0.4cm rgba(0, 0, 0, 0.5)) // per le img png
    width: 11.25vw
    height: 11.25vw
    // margin-bottom: 20vh
    // border: solid 1px red
    // display: inline-block

  .logo-small
    height: 6vh
    // filter: drop-shadow(0cm 0cm 0.2cm rgba(0, 0, 0, 0.1)) // per le img png


  // #logo-Rhino
  //   width: 23vh
  //   height: auto

  #logo-Grasshopper 
    // position: absolute
    width: 10vh
    top: 55%
    left: 75%
    // height: auto
    // left: 100%
    // top: 50% // spostato e trasformato dall'angolo in basso a destra del logo di Rhino
    // // transform: translate(-75%, -50%)
    // filter: drop-shadow(0.3cm 0.5cm 0.5cm rgba(33, 255, 25, 0.158)) // per le img png

  // #logoRhino-container
  // #softwares3D // per qualche ragione occorre dichiarare tutto il percorso altrimenti la dichiarazione precedente - quella per tutti i loghi - vince. Sembra avere la precedenza.
  //   span
  //     border: solid 1px black
  //     position: absolute
  //     top: 20vh
        // bottom: 0vh
        // transform: translateX(-30%)



  #grassScreenshot-img
    width: 100vw
    height: auto
    position: absolute
    left: -10vw
    top: -35vh

  #grassScreenshot
    background-image: url("../static/earlyWorks/images/grassScreenshot.png")
    background-size: contain
    // background-position: top
    background-repeat: no-repeat
    // transform: translateY(-5%)
    // width: 80vw
    height: 65vh
    border: 1px solid #ddd


  .conch-img
    display: block
    position: absolute

  .conchtop-img
    display: none  


  #conch1
    top: 323/1080*100vh
    left: 135/1920*100vw
    height: 316/1080*100vh
    filter: drop-shadow(-0.5cm 0.5cm 0.5cm rgba(0, 0, 0, 0.2))
    opacity: 1

  #conch2
    top: 289/1080*100vh
    left: 261/1920*100vw
    height: 446/1080*100vh
    filter: drop-shadow(-1cm 0.5cm 1cm rgba(0, 0, 0, 0.5))
    opacity: 1

  #conch3
    top: 194/1080*100vh
    left: 456/1920*100vw
    height: 789/1080*100vh
    filter: drop-shadow(-1cm 0.5cm 1.5cm rgba(0, 0, 0, 0.6))
    opacity: 1

  #grasshopper-text
    position: absolute
    // display: inline-block
    bottom: 0vh
    left: 10vh
    // text-align: left
    width: 45vw

  $fresaSize: 110vh

  #fresata-container
    // margin: auto
    height: $fresaSize
    width: 100vw
    display: flex
    align-content: center
    justify-content: center
    // background-color: olive


  #fresataBW
    background-image: url("../static/earlyWorks/images/fresataOrganicaBW.png")
    position: absolute
    // left: 0
    // top: 0
    background-position: center
    background-repeat: no-repeat
    background-size: cover
    height: 1284/1284*$fresaSize
    width: 1450/1284*$fresaSize // è un problema per landscape
    z-index: 1
    // box-shadow: 0 0 20px 5px #000000 // implementare ombra animata allo spostamento


  #fresataPurple
    background-image: url("../static/earlyWorks/images/fresataOrganicaPurpleColor.png")
    position: absolute
    background-position: center
    background-repeat: no-repeat
    background-size: cover
    height: 1284/1284*$fresaSize
    width: 1450/1284*$fresaSize // è un problema per landscape
    z-index: 2

  .overlay-black
    position: absolute
    width: 100%
    height: 100%
    background: rgb(0,0,0) // sovrascrive il gradiente del mobile
    z-index: 0
    opacity: 0
    left: 0
    top: 0

  // $fresaSpanSize:  - 1450/1284/2 * $fresaSize + (30 + 50) * 16vh/9 //aspect ratio


  #fresata-text
    position: absolute
    padding: 0vh 6vw
    // margin-right: 4vh
    right: 0
    top: 50vh
    transform: translateY(-50%)
    // float:right
    width: 45vw
    box-sizing: border-box

  #fresataLegno
    position: absolute
    // padding: 0vh 5vh
    margin: 0 5vh 0
    right: 0
    top: 50vh
    transform: translateY(-50%)
    // box-sizing: border-box
    // float:right
    width: 40vw
    height: 100vh
    background-image: url("../static/earlyWorks/images/fresataLegno.jpg")
    background-size: contain
    background-position: center
    background-repeat: no-repeat
    // p
    //   display: absolute
    //   bottom: 0
    //   left: 0
    //   // transform: translateY(100%)
    //   padding: 0vh 10vh


  #abbraccio-text
    right: 8vh
    bottom: 1vw
    p
      text-align: right
      img
        width: 6vh // ripristina l'altezza di logo-small. non ho trovato un modo che fa la annulli da questa dichiarazione semplicemente, in modo da farla tornare in automatico
        height: auto
        // float: right
        // display: block



  #abbraccioOverlay
    // background-color: olive
    position: absolute
    width: 100%
    height: 100%
    opacity: 0
    background: rgb(0,0,0)
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)

  // SHOES

  #v-Shoes
    height: 100vh
    background-image: url("../static/earlyWorks/images/v-Shoes-bg.jpg")
    background-size: 100% 100%
    // border: solid 10px red
    // box-sizing: border-box

  #shoesOverlay
    // background-color: white
    background-image: url("../static/earlyWorks/images/RhinoGridLight-bg.jpg")
    position: absolute
    width: 100%
    height: 100%
    background-size: 100% 100%
    // background-color: black
    z-index: 0
    // opacity: 1
    left: 0
    top: 0

  .shoes-img
    height: 90vh
    width: auto
    position: absolute
    left: 50vw
    top: 50vh
    transform: translate(-50%, -50%)

  #shoes-wireframe
    filter: drop-shadow(1vh 0vh 1cm rgba(0, 0, 0, 0.4)) // per le img png

  #shoes-text
    position: absolute
    // padding: 0vh 14vh
    // margin-right: 4vh
    left: 14vh
    top: 50%
    transform: translateY(-50%)
    // float:right
    width: 35vw
    box-sizing: border-box
    p
      color: #272c32

  // SFERA

  #v-Sfera
    background-image: url("../static/earlyWorks/images/v-Sfera-bg.jpg")
    background-size: 100% 100%
    // background-position: 80%
    height: 100vh

  #sfera-img
    position: absolute
    height: 80vh
    width: auto
    margin: 0
    transform: translateX(0%)
    right: 10vw
    left: auto
    bottom: 10vh
    filter: drop-shadow(0vh 0vh 2vh rgba(0, 0, 0, 0.3))


  #sfera-text
    position: absolute
    // padding: 0vh 14vh
    // margin-right: 4vh
    left: 14vh
    top: 50%
    bottom: auto
    transform: translateY(-50%)
    // float:right
    width: 40vw
    box-sizing: border-box
    // border: solid 1px red
    p
      color: #f6f4ec

  // LESMO

  #lesmoOverlay
    background-image: url("../static/earlyWorks/images/v-lesmo-bgOverlay.jpg")
    position: absolute
    width: 100%
    height: 100%
    background-size: 100% 100%
    // background-color: black
    z-index: 0
    // opacity: 1
    left: 0
    top: 0

  #lesmo-title 
    position: absolute
    left: 24vw
    top: 5vh

  .title
    font-size: 12vh
    margin-bottom: 5vh
    margin-top: 0
    text-align: left
    text-shadow: 0 0 2vh rgba(0, 0, 0, 0.1)


  #lesmo-text
    position: absolute
    padding: 0vh 8vh
    text-align: left
    right: 0
    top: 30vh
    width: 38vw
    box-sizing: border-box
    p
      // color: #eeeeeeee
      // font-weight: 600
    img
      float: right

  #lesmo-btm-border
    position: absolute
    bottom: 0
    width: 100%
    height: 2cm
    background-color: rgba(255,255,255,0.5)
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 100%)
    z-index: 0

  #lesmo-subText
    position: absolute
    padding: 0vh 8vh
    // margin-right: 4vh
    right: 0
    top: 40vh
    width: 35vw
    // border: solid 1px
    box-sizing: border-box
    color: black
    p
      color: #eeeeee
      font-weight: 600

  #v-LesmoGallery
    overflow: visible // stranamente overflow-y: visible crea una scrollbar, invece overflow: visible no, come dovrebbe essere. Anche se non voglio overflow in x...


  img#lesmo-facciata-img
    position: absolute
    bottom: 0
    left: 50vw
    transform: translateX(-50%)
    width: 70vw
    height: auto
    z-index: 1


  $lesmoGalleryHeight: 60vh

  #lesmo-gallery-container
    // margin-top: 5vh
    position: absolute
    width: 100vw
    // background-color: gray


  .lesmo-gallery-img
    position: relative // così rimangono nel flow del documento e occupano spazio, e con top left ecc. si possono muovere rispetto alla posizione di partenza.
    display: block // così ogni immagine occupa una riga.
    // margin: auto
    width: auto
    height: $lesmoGalleryHeight
    border-radius: 0.3cm
    filter: drop-shadow(0vh 0vh 1cm rgba(0, 0, 0, 0.2))  // per le img png


  #lesmo-img1 // 800x450px
    margin-top: 5vh
    // top: 5vh
    left: 8vw

  #lesmo-img2 // 600x450px
    right: 8vw
    height: $lesmoGalleryHeight * 0.8
    float: right

  #lesmo-img3 // 800x386px
    left: 8vw
    top: 20vh
    width: 45vw
    height: auto


  #lesmo-img4 // 800x386px
    right: 8vw
    float: right
    height: $lesmoGalleryHeight * 0.8
    

  #lesmo-img5
    left: 5vw
    top: 5vh

  #lesmo-img6
    top: -5vh
    right: 10vw
    float: right
    height: $lesmoGalleryHeight * 0.7

  #lesmo-img7
    left: 8vw
    top: 10vh


  #lesmo-img8
    float: right
    right: 12vw




  #v-Programmazione
    p 
      text-align: center
      width: 70%
      margin: 0 auto 8vh
      // border: solid 1px red
      // margin-bottom: 



  // TOLOMEO
    

  #Tolomeo-text
    position: absolute
    right: 4vw  
    top: 3.37vw
    text-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.3)
    // border: solid 1px red
    width: 45vw
    // overflow-wrap: break-word
    p
      white-space: normal

  #Tolomeo-title
    // background-color: #f3ec78
    background-image: linear-gradient(70deg, #eeeeeedd, #eeeeee00)
    background-size: 100%
    -webkit-background-clip: text
    -webkit-text-fill-color: transparent
    // text-shadow: -1px -1px 1px rgba(255, 255, 255, 1)
    // position: relative
    // display: inline-block
    // right: 10.5vw  
    // top: 3.37vw

  #startButton-Tolomeo
    position: absolute
    height: 10vh
    width: 10vh
    border-radius: 5vh
    background-color: red
    left: 50%
    top: 50%
    transform: translate(-50% -50%)
    z-index: 4

  #Tolomeo-span
    // position: relative
    // padding: 0vh 10vh 0vh 0vh
    // right: 0vh
    // top: 40%
    // transform: translateY(-50%)
    // width: 40vw
    // box-sizing: border-box
    // text-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.3)

  #Tolomeo-container  
    width: 100%
    height: 100%

    
  .italic
    font-style: italic  

  .regular
    font-style: normal 

  // BLINK  

  #v-Prototipazione
    h1
      text-align: center
      font-size: 10vh
      text-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.2)
    h2
      text-align: center
      font-size: 8vh
      color: #f7df0a
    h3
      text-align: center
      color: #f7df0a
      font-size: 10vh
      text-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.2)  
    p 
      text-align: center
      font-weight: 600
      max-width: 50%
      margin: auto

  #v-blink
    height: 158vh
    .description
      top: 70vh
    p 
      text-align: center
      width: 70%
      margin: auto
      // border: solid 1px red

  #blinkLogo-img 
    width: 30vw 
    height: auto
    z-index: 1
    filter: drop-shadow(0 0 1.5vh rgba(0, 0, 0, 0.4)) // per le img png
    margin-top: 0vw
    top: 5vh

  #blinkCentral-img
    position: absolute
    // display: inline-block
    margin: auto
    top: 33vh
    width: 75vw
    left: 50%
    margin-left: -37.5vw // la metà della larghezza
    filter: drop-shadow(0 0 5vh rgba(0, 0, 0, 0.5)) // per le img png


  #blinkSite-btn
    // position: absolute
    // right: 10vw
    // bottom: 10vh
    // background-color: #272c32
    padding: 0.28vw 1.125vw
    margin: 5vh auto
    border: solid 2px rgba(0,0,0,0.3)
    a
      font-family: avenir next, avenir, helvetica neue, 'Market Fresh'
      font-weight: 600
      font-size: 4vh
      color: #eeeeee
      text-decoration: none





  #moreSymbol
    font-family: consolas // mi sa che va scaricato
    font-size: 2.8vw  

  // .floatingZ-centered
  //   position: absolute
  //   display: block
  //   // left: 50vw
  //   // transform: translate(-50%, 0%)
  //   margin: auto

  #crg-container
    // background-color: olive
    position: absolute
    width: 70vw
    height: 100vh
    left: 0


  #crg-text
    position: absolute
    padding: 0vh 14vh
    // margin-right: 4vh
    right: 0
    top: 50%
    transform: translateY(-50%)
    // float:right
    width: 45vw
    box-sizing: border-box


































































































































































































@media only screen and (min-device-width: 320px) and (max-device-height: 600px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) 

  .intestazione-container
    h1
      letter-spacing: -0.6vh
    h2
      // letter-spacing: 0.1vh
      // color: black
